<template>
  <div class="upgradeRecord-list">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          search: [],
          columns: [{
              label: "固件版本",
              key: "version",
            },
            {
              label: "固件文件名",
              key: "fileName",
            },
            {
              label: "升级结果",
              key: "stateStr",
            },
            {
              label: "升级时间",
              key: "createdTime",
            },
            {
              label: "操作人",
              key: "userName",
            },
            {
              label: "备注",
              key: "remarks",
            },
          ]
        },
      };
    },
    created() {
      console.log("upgradeRecord created!!");

    },
    activated() {
      console.log("upgradeRecord activated!!");
    },
    mounted() {},
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          counterId: this.$route.query.id
        };
        this.post("mall-service/v1/mall/counterUpgradeRecord/query", dto, {
          isUseResponse: true
        }).then(res => {
          if(res.data.data.data) {
            res.data.data.data.forEach(item => {
              if (item.state == 0){
                item.stateStr = '升级成功'
                item.remarks = '--'
              } else if(item.state == 100) {
                item.stateStr = '升级中'
                item.remarks = '--'
              }else {
                item.stateStr = '升级失败'
                item.remarks = ['此版本已经升级过','电量低','失败','型号错误','低版本无法升级高版本','参数错误','文件太大，存储空间不足','http服务器获取到的参数与MQTT下发的参数不匹配',
                    '文件太小或不完整','关键参数错误，快速退出升级状态','部分从柜升级失败','全部从柜升级失败','密钥错误'][item.state - 1]
              }
            });
          }
          opt.cb(res.data.data);
        });
      },
    }
  };
</script>
<style lang="scss" scoped>
  .upgradeRecord-list {
    height: calc(100% - 143px);
  }
</style>