<template>
<div class="rowCabinetRecord-list">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
</div>
    
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    search: [],
                    columns: [{
                            label: "柜门编号",
                            key: "doorCode"
                        },
                        {
                            label: "货管员",
                            key: "counterUserName",
                        },
                        {
                            label: "货管员手机号",
                            key: "counterUserPhone"
                        },
                        {
                            label: "使用人姓名",
                            key: "consumerInfoName"
                        },
                        {
                            label: "使用人手机号",
                            key: "consumerInfoPhone"
                        },
                        {
                            label: "使用期限",
                            key: "serviceLife"
                        },
                        {
                            label: "排柜时间",
                            key: "createdTime"
                        },
                        {
                            label: "使用状态",
                            key: "stateStr"
                        },
                    ]
                },
            };
        },
        created() {
            console.log("rowCabinetRecord created!!");
        },
        activated() {
            console.log("rowCabinetRecord activated!!");
            let containerType = this.$route.query.type;
            let myArray = [];
            this.opt.columns.forEach(item => {
                myArray.push(item.key);
            })
            // L25AF L25CF 增加是否注册人脸；L25AK L25CK 增加是否注册IC卡
            if (containerType == 12 || containerType == 13) {
                if (myArray.indexOf('isRegisteredCardStr') != -1 && myArray.indexOf('isRegisteredFaceStr') == -1) {
                    this.opt.columns.splice(5, 1, {
                        label: "是否注册人脸",
                        key: "isRegisteredFaceStr"
                    })
                }else if (myArray.indexOf('isRegisteredCardStr') == -1 && myArray.indexOf('isRegisteredFaceStr') == -1) {
                    this.opt.columns.splice(5, 0, {
                        label: "是否注册人脸",
                        key: "isRegisteredFaceStr"
                    })
                }
            }else if (containerType == 14 || containerType == 15) {
                if (myArray.indexOf('isRegisteredFaceStr') != -1 && myArray.indexOf('isRegisteredCardStr') == -1) {
                    this.opt.columns.splice(5, 1, {
                        label: "是否注册IC卡",
                        key: "isRegisteredCardStr"
                    })
                }else if (myArray.indexOf('isRegisteredFaceStr') == -1 && myArray.indexOf('isRegisteredCardStr') == -1) {
                    this.opt.columns.splice(5, 0, {
                        label: "是否注册IC卡",
                        key: "isRegisteredCardStr"
                    })
                }
            }else {
                if(myArray.indexOf('isRegisteredFaceStr') != -1 || myArray.indexOf('isRegisteredCardStr') != -1) {
                    this.opt.columns.splice(5,1);
                }
            }
        },
        mounted() {},
        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    counterId: this.$route.query.id
                };
                this.post("mall-service/rowCounterRecord/page/query", dto, {
                    isUseResponse: true
                }).then(res => {
                    res.data.data = res.data.list;
                    for (let i = 0; i < res.data.data.length; i++) {
                        let item = res.data.data[i];
                        // 在用  已过期 停用
                        item.stateStr = [
                            "在用",
                            "已过期",
                            "停用"
                        ][item.state - 1];
                        if (item.doorCode && item.doorCode.length < 2) {
                            item.doorCode = '0' + item.doorCode;
                        }
                        if (item.region) {
                            item.doorCode = item.region + item.regionNumber + '-' + item.doorCode;
                        }
                        item.serviceLife = '永久';
                        if(item.isFace == 0) {
                            item.isRegisteredFaceStr = '是';
                        }else {
                            item.isRegisteredFaceStr = '否';
                        }
                        if(item.isCard == 1) {
                            item.isRegisteredCardStr = '是';
                        }else {
                            item.isRegisteredCardStr = '否';
                        }
                    }
                    opt.cb(res.data);
                });
            },
        }
    };
</script>
<style lang="scss" scoped>
    .rowCabinetRecord-list {
       height: calc(100% - 143px); 
    }
</style>