var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tansferRecord-list" },
    [
      _c("app-list", {
        ref: "appList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看照片",
            width: "900px",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "checkPicBox" }, [
            _c("div", { staticClass: "photoTitle" }, [_vm._v("移货前照片")]),
            _c(
              "div",
              { staticClass: "checkPic transferBefore" },
              _vm._l(_vm.beforePicUrls, function(item, index) {
                return _c("img", { key: index, attrs: { src: item } })
              }),
              0
            ),
            _c("div", { staticClass: "photoTitle transferAfter" }, [
              _vm._v("移货后照片")
            ]),
            _c(
              "div",
              { staticClass: "checkPic" },
              _vm._l(_vm.afterPicUrls, function(item, index) {
                return _c("img", { key: index, attrs: { src: item } })
              }),
              0
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }