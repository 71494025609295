<template>
  <div class="stockRecord-list">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
       opt: {
        search:[],
        columns: []
        },
    };
  },
  created() {
    console.log("stockRecord created!!");
    
  },
  activated() {
    console.log("stockRecord activated!!");
    let containerType=this.$route.params.type;
    this.containerTypeVal = this.$route.query.type;
      if(this.containerTypeVal==2016) {
        this.opt.columns.splice(0,this.opt.columns.length)
        this.opt.columns.push(
          { label: "柜门编号", key: "doorName", align: "center"},
          { label: "操作类型(存)", key: "typeStock", align: "center" },
          { label: "用户手机号码", key: "stockUserPhone", align: "center"},
          { label: "存货时间", key: "stockTime", align: "center"},
          { label: "开柜门状态", key: "stockStateStr", align: "center"},
          { label: "取件码", key: "takeCode", align: "center"},
          { label: "取件人信息", key: "takeUserInfo", align: "center"},
          { label: "操作类型(取)", key: "typeTake", align: "center"},
          {label: "取件方式", key: "takeMethodStr", align: "center"},
          { label: "取件时间", key: "takeTimeVal", align: "center"},
          { label: "开柜门状态", key: "takeStateStr", align: "center"},
        ) 
      }else {
        this.opt.columns.splice(0,this.opt.columns.length)
        this.opt.columns.push(
          { label: "柜门编号", key: "doorName", align: "center"},
          { label: "操作类型(存)", key: "typeStock", align: "center" },
          { label: "用户ID", key: "consumerId", align: "center"},
          { label: "存货时间", key: "stockTime", align: "center"},
          { label: "开柜门状态", key: "stockStateStr", align: "center"},
          { label: "取件码", key: "takeCode", align: "center"},
          { label: "取件人信息", key: "takeUserInfo", align: "center"},
          { label: "操作类型(取)", key: "typeTake", align: "center"},
          { label: "取件时间", key: "takeTimeVal", align: "center"},
          { label: "开柜门状态", key: "takeStateStr", align: "center"},
        ) 
        if (['L22','L22B','L22BX','L22BD','L25'].indexOf(containerType) != -1) {
          if (this.opt.columns[0].key != "doorName") {
            this.opt.columns.unshift({
              label: "柜门编号",
              key: "doorName"
            })
          }
        } else {
          this.opt.columns.forEach((item, index) => {
            if (item.key == "doorName") {
              this.opt.columns.splice(index, 1);
            }
          })
        }
      }
  },
  mounted(){},
  methods: {
    onGet(opt) {
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        counterId: this.$route.query.id
      };
      this.post("mall-service/stockRecord/page/query", dto, {
        isUseResponse: true
      }).then(res => {
        res.data.data = res.data.list;
        let containerType=this.$route.params.type; 
        if(containerType=='L2K' || containerType=='L21' || containerType=='L22') {
          res.data.list.forEach(item => {
            item.stockStateStr = ['成功','失败','','成功'][item.stockState];
            item.typeStock = "存货";
            if(!item.takeTime) {
              item.typeTake = "--";
              item.takeTimeVal = "--";
              item.openStateTake = "--";
              item.takeStateStr = '--';
            }else {
              item.typeTake = "取件";
              item.takeTimeVal = item.takeTime;
              item.takeStateStr = ['成功','失败','','成功'][item.takeState];
            }
          });
        }else if(['L21BD','L21BX','L22BD','L22BX','L25'].indexOf(containerType) != -1 || [2016].indexOf(Number(this.containerTypeVal)) != -1) {
          res.data.list.forEach(item => {
            item.stockStateStr = ['成功','失败','连接失败','系统繁忙'][item.stockState];
            item.typeStock = "存货";
            if(!item.takeTime) {
              item.typeTake = "--";
              item.takeTimeVal = "--";
              item.openStateTake = "--";
              item.takeStateStr = '--';
            }else {
              item.typeTake = "取件";
              item.takeTimeVal = item.takeTime;
              item.takeStateStr = ['成功','失败','连接失败','系统繁忙'][item.takeState];
            }
            if(item.region) {
              item.doorName = item.region + item.regionNumber + '-' + item.doorName;
            }
            // 1 扫码取货  2 密码取货  3 管理员取货
            if(item.takeMethod) {
              item.takeMethodStr = ['扫码取件','密码取件','超时货管员取件'][item.takeMethod - 1];
            }else {
              item.takeMethodStr = '--';
            }
           item.stockUserPhone = item.stockUserPhone ? item.stockUserPhone : '--';
          });
        }
        res.data.data.forEach(item => {
          if(item.takeUserName || item.takeUserPhone) {
            item.takeUserInfo = (item.takeUserName ? (item.takeUserName + ' ') : '') + (item.takeUserPhone ? item.takeUserPhone : '')
          }else {
            item.takeUserInfo = '--';
          }
        })
        opt.cb(res.data);
      });
    },
  }
};
</script>
<style lang="scss" scoped>
  .stockRecord-list{
    height: calc(100% - 143px);
  }
</style>