<template>
  <div class="openDoorRecord-list">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          search: [],
          columns: [{
              label: "开柜门方式",
              key: "wayStr",
            },
            {
              label: "开柜人角色",
              key: "roleStr"
            },
            {
              label: "开柜人",
              key: "realName"
            },
            {
              label: "开柜人手机号",
              key: "phone"
            },
            {
              label: "开柜门时间",
              key: "createdTime"
            },
            {
              label: "柜门状态",
              key: "onlineStateStr"
            },
            {
              label: "开柜门结果",
              key: "resultStr"
            },
          ]
        },
      };
    },
    created() {
      console.log("openDoorRecord created!!");

    },
    activated() {
      console.log("openDoorRecord activated!!");
      let containerType = this.$route.params.type;
      if (['L22', 'L22B', 'L22BD', 'L22BX', 'L25', 'L25A','L25AB'].indexOf(containerType) != -1) {
        if (this.opt.columns[0].key != "doorName") {
          this.opt.columns.unshift({
            label: "柜门编号",
            key: "doorName"
          })
        }
      } else {
        this.opt.columns.forEach((item, index) => {
          if (item.key == "doorName") {
            this.opt.columns.splice(index, 1);
          }
        })
      }
    },
    mounted() {},
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          mac: this.$route.query.mac
        };
        this.post("mall-service/openDoorRecord/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          let containerType = this.$route.params.type;
          if (containerType == 'L2K' || containerType == 'L21' || containerType == 'L22') {
            res.data.list.forEach(item => {
              item.roleStr = ['货管员', '工程人员', '消费者'][item.role - 1];
              item.realName = item.realName ? item.realName : '--';
              item.phone = item.phone ? item.phone : '--';
              if (item.result == 100) {
                item.resultStr = "失败";
              } else {
                item.resultStr = ['成功', '失败', '', '成功(重复操作)', '', '', '', '', '失败', '失败'][item.result];
              }
              item.onlineStateStr = ['离线', '在线'][item.onlineState];
              if (item.way == "1") {
                item.wayStr = '手机开柜门';
              }
            });
          } else { //'L21BD', 'L21BX', 'L22BD', 'L22BX', 'L25','L25A'
            res.data.list.forEach(item => {
              item.realName = item.realName ? item.realName : '--';
              item.phone = item.phone ? item.phone : '--';
              if (item.result == 100) {
                 item.resultStr = '失败（开门超时）';
              } else {
                item.resultStr = ['成功', '失败（柜门弹开失败）', '失败（连接从柜门失败）', '失败（重复操作）', '失败（柜门编号错误）', '柜门已经是打开状态',
                  '蓝牙芯片通讯失败', '连接附属开柜设备失败',' 存储数据已满（人脸/卡）','重复添加（人脸/卡）','无此用户（人脸/卡）','校验失败（密钥错误）','检测不到货道转动',
                  '检测不到红外变化','货道号错误(货道号超出范围)','商品掉落成功但检测不到货道转动','红外检测被挡住','一次开门数量超过最大限制','设备忙，正在寻找初始位置',
                  '设备寻找初始位置失败（设备故障）','设备寻找目标位置失败（设备故障）','柜门超时未关闭','插销缩进失败（设备故障）','插销伸出失败（设备故障）','设备更新初始位置失败（设备故障）',
                  '设备还未开始寻找初始位置（门未关）'
                ][item.result];
              }
              item.onlineStateStr = ['离线', '在线'][item.onlineState];
              if (item.way == "1") {
                item.wayStr = '手机开柜门';
              } else if (item.way == "2") {
                item.wayStr = '人脸识别开柜门';
              } else if (item.way == "3") { //way值待对接
                item.wayStr = '刷卡开柜门';
              } else if (item.way == "4") {
                item.wayStr = '密码开柜门';
              }
              if (item.region) {
                item.doorName = item.region + item.regionNumber + '-' + item.doorName;
              }
              if (['L21BD', 'L21BX', 'L22BD', 'L22BX', 'L25'].indexOf(containerType) != -1) {
                item.roleStr = ['货管员', '工程人员', '消费者'][item.role - 1];
              } else if (['L25A','L25AB'].indexOf(containerType) != -1) {
                item.roleStr = ['货管员', '工程人员', '用户'][item.role - 1];
              }
            });
          }

          opt.cb(res.data);
        });
      },
    }
  };
</script>
<style lang="scss" scoped>
  .openDoorRecord-list {
    height: calc(100% - 143px);
  }
</style>