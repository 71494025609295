var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "replenishmentRecord-list" },
    [
      _c("app-list", {
        ref: "appList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      }),
      _c(
        "div",
        { staticClass: "offDetail" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: "下架详情",
                width: "700px",
                visible: _vm.offDetailDialogVisible,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.offDetailDialogVisible = $event
                }
              }
            },
            [
              _c("div", { staticClass: "offDetail-content-box" }, [
                _c("div", { staticClass: "offDetail-content" }, [
                  _c("div", { staticClass: "offDetail-content-row" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("货柜信息")]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.obj.groupName))
                    ])
                  ]),
                  _c("div", { staticClass: "offDetail-content-row" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("下架商品名称")
                    ]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.obj.itemsName))
                    ])
                  ]),
                  _c("div", { staticClass: "offDetail-content-row" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("下架时间")]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.obj.shelvesTime))
                    ])
                  ]),
                  _c("div", { staticClass: "offDetail-content-row" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("下架人手机号")
                    ]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.obj.shelvesPhone))
                    ])
                  ]),
                  _c("div", { staticClass: "offDetail-content-row" }, [
                    _c("div", { staticClass: "title" }, [_vm._v("下架人姓名")]),
                    _c("div", { staticClass: "content" }, [
                      _vm._v(_vm._s(_vm.obj.shelvesUserName))
                    ])
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "dialog-footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.offDetailDialogVisible = false
                        }
                      }
                    },
                    [_vm._v("关闭")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }