<template>
  <div class="orderRecord-list">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
export default {
  data() {
    let _this = this;
    return {
       opt: {
        search:[],
        columns: [
          { label: "订单编号", key: "id",},
          { label: "商品品类", key: "categoryName" },
          { label: "商品名称", key: "itemName" },
          { label: "数量", key: "buyCounts" },
          { label: "订单金额", key: "totalAmount" },
          { label: "下单人", key: "receiverName" },
          { label: "手机号", key: "phone" },
          { label: "下单时间", key: "createdTime" },
          { label: "支付时间", key: "payTime" },
          { label: "支付状态", key: "stateStr" },
        ]
      },
    };
  },
  created() {
    console.log("orderRecord created!!");
    
  },
  activated() {
    console.log("orderRecord activated!!");
    let containerType = this.$route.params.type;
    let myArray = [];
    this.opt.columns.forEach(item => {
      myArray.push(item.key);
    })
    if(containerType == "L2K") {
      this.opt.columns.forEach((item,index) => {
        if(item.key == "consumerId") {
          // 将下单用户id改成下单人
          this.opt.columns.splice(index,1,{
            label: "下单人",
            key: "receiverName"
          });
          // 增加手机号字段
          this.opt.columns.splice(index+1,0,{
            label: "手机号",
            key: "phone"
          });
        }
        // 删除柜门编号
        if(item.key == "counterDoorName") {
          this.opt.columns.splice(index,1);
        }
      })
    }else if(containerType == "L21"){
      this.opt.columns.forEach((item,index) => {
        if(item.key == "receiverName") {
          // 将下单人替换成下单用户id
          this.opt.columns.splice(index,1,{
            label: "下单用户id",
            key: "consumerId"
          });
        }
        if(item.key == "phone") {
          // L21和L22订单记录没有手机号
          this.opt.columns.splice(index,1);
        }
        // 删除柜门编号
        if(item.key == "counterDoorName") {
          this.opt.columns.splice(index,1);
        }
      })
    }else if(containerType == "L22") {
      this.opt.columns.forEach((item,index) => {
        if(item.key == "receiverName") {
          // 将下单人替换成下单用户id
          this.opt.columns.splice(index,1,{
            label: "下单用户id",
            key: "consumerId"
          });
        }
        if(item.key == "phone") {
          // L21和L22订单记录没有手机号
          this.opt.columns.splice(index,1);
        }
      })
      if(myArray.indexOf("counterDoorName") == -1) {
        this.opt.columns.splice(1,0,{
          label: "柜门编号",
          key: "counterDoorName"
        })
      }
    }
  },
  mounted(){},
  methods: {
    onGet(opt) {
      let dto = {
        pageNum: opt.skip,
        pageSize: opt.limit,
        counterId: this.$route.query.id
      };
      this.post("mall-service/orders/page/query", dto, {
        isUseResponse: true
      }).then(res => {
        res.data.data = res.data.list;
        res.data.list.forEach(item => {
          item.stateStr = ["未支付","支付中","已支付","支付失败","订单取消","支付超时"][item.state - 1]//1新建未支付，2 支付中，3已支付(支付成功)，4支付失败  5 订单取消 6支付超时
          var categoryNameList = [];
          var itemNameList = [];
          var counterDoorNameList = [];
          if(item.orderItemsRespList) {
            item.orderItemsRespList.forEach((it,idx) => {
              categoryNameList.push(it.categoryName);
              itemNameList.push(it.itemName);
              counterDoorNameList.push(it.counterDoorName);
            })
            item.categoryName = categoryNameList.join('、');
            item.itemName = itemNameList.join('、');
            item.counterDoorName = counterDoorNameList.join('、');
          }
          item.payTime = item.payTime ? item.payTime : "--";
        });
        opt.cb(res.data);
      });
    },
  }
};
</script>
<style lang="scss" scoped>
  .orderRecord-list {
    height: calc(100% - 143px);
  }
</style>