<template>
    <div class="main">
        <div>
            {{storeName}}
        </div>
        <div class="main-title">
            <span class="main-address">{{storeAddress}}</span>
            <span class="main-address" v-for="(item,index) in freightTrackList" :key='index'>货道类型{{item.channelType}}：<span
                    class="highlightText">{{item.count}}</span>个</span>
        </div>
        <div class="main-content">
            <div class="main-content-left">
                <div class="productView">
                    <div class="productView-tip">
                        <div class="tip-cont">
                            <div class="tip-img img1"></div>
                            <div class="tip-title">满货<span>{{full}}</span></div>
                        </div>
                        <div class="tip-cont">
                            <div class="tip-img img2"></div>
                            <div class="tip-title">缺货<span>{{lack}}</span></div>
                        </div>
                        <div class="tip-cont">
                            <div class="tip-img img3"></div>
                            <div class="tip-title">无货<span>{{miss}}</span></div>
                        </div>
                    </div>
                    <div class="productView-row" v-for="(item,index) in viewList" :key="index">
                        <div class="row-top"
                            :class="{'img1': item.cargoState==1,'img2': item.cargoState==0,'img3': item.cargoState==2}">
                            {{item.code}}</div>
                            <!-- 无货 -->
                        <div class="row-center" v-if="item.cargoState==2">
                            <div style="width:100%;height:70px;text-align: center">
                                <img class="row-img-cry" src="../../assets/ic_cry.png">
                            </div>
                        </div>
                        <!-- 缺货、满货 -->
                        <div class="row-center" v-else>
                            <img class="row-img" :src="item.itemPic">
                            <div class="row-text">{{item.itemName}}</div>
                        </div>
                        <div class="row-bottom">
                            <span v-show="item.cargoState!=2">{{item.itemPrice + ' '}}</span>
                            <span>{{item.cargoStateStr}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-content-right">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="code" align="center" label="货道编号">
                    </el-table-column>
                    <el-table-column prop="channelType" align="center" label="货道类型">
                    </el-table-column>
                    <el-table-column prop="specification" align="center" label="货位长宽高">
                    </el-table-column>
                    <el-table-column prop="workableStr" align="center" label="货道状态">
                    </el-table-column>
                    <el-table-column prop="itemNameStr" align="center" label="货道商品">
                    </el-table-column>
                    <el-table-column prop="itemPrice" align="center" label="商品价格">
                    </el-table-column>
                    <el-table-column prop="cargoSpace" align="center" label="货位">
                    </el-table-column>
                    <el-table-column prop="inventory" align="center" label="库存">
                    </el-table-column>
                    <el-table-column prop="freeSpace" align="center" label="空余货位">
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>

</template>
<script>
    export default {
        data() {
            return {
                storeName: '',
                storeAddress: '',
                freightTrackList: [],
                viewList: [],
                tableData: [{
                    code: '',
                    channelType: '',
                    specification: '',
                    cargoStateStr: '',
                    itemName:'',
                    itemNameStr: '',
                    itemPrice: 0,
                    cargoSpace:0,
                    inventory:0,
                    freeSpace:0,
                }],
                full: 0,
                lack: 0,
                miss: 0,
                counterId: '',
            }
        },
        activated() {
            this.storeAddress = this.$route.query.storeAddress || '--';
            this.storeName = this.$route.query.storeName || '--';
            this.counterId = this.$route.query.id;
            this.tableData = [];
            this.viewList = [];
            this.getTypeList();
            if([20,21].indexOf(Number(this.$route.query.type)) != -1) {
                this.getDetail();
            }
        },
        methods: {
            getDetail() {
                this.post('mall-service/v1/door/channel/detail?counterId=' + this.counterId, {}, {
                    isUseResponse: true,
                }).then(res => {
                    if (res.data.code == 0) {
                        this.tableData = res.data.data.channelInfoVoList || [];
                        this.tableData.forEach(item => {
                            item.itemPrice = item.itemPrice ? '￥' + Number(item.itemPrice).toFixed(2) :
                                '￥0.00';
                            item.cargoStateStr = ['缺货', '满货','无货'][item.cargoState];
                            item.workableStr = ['禁用','启用'][item.workable];
                            item.itemNameStr = item.itemName || '--';
                            if(item.code < 10) {
                                item.code = '0' + item.code;
                            }
                            
                        });
                        this.viewList = res.data.data.channelInfoVoList || [];
                        this.full = res.data.data.full || 0;
                        this.lack = res.data.data.lack || 0;
                        this.miss = res.data.data.miss || 0;
                    }
                })
            },
            getTypeList() {
                this.post('mall-service/v1/door/channel/type?counterId=' + this.counterId, {}, {
                    isUseResponse: true,
                }).then(res => {
                    if (res.data.code == 0) {
                        this.freightTrackList = res.data.data || [];
                    }
                })
            },
        },

    }
</script>
<style lang="scss" scoped>
    .main-title {
        padding: 10px 0;
    }

    .main-address {
        margin-right: 40px;
        color: #666666;
        font-size: 15px;
    }

    .main-content {
        display: flex;
        padding-bottom: 20px;
    }

    .main-content-left {
        width: 28%;
    }

    .main-content-right {
        width: 58%;
    }

    .highlightText {
        color: #30BAC1;
    }

    .productView-tip {
        display: flex;
        justify-content: flex-end;
        font-size: 14px;
        padding-top: 15px;
        padding-right: 66px;
    }

    .tip-cont {
        display: flex;
        padding-left: 5px;
    }

    .tip-img {
        width: 16px;
        height: 16px;
        border-radius: 50%;
    }

    .img1 {
        background-color: royalblue;
    }

    .img2 {
        background-color: #f56cae;
    }

    .img3 {
        background-color: #909399;
    }

    .productView-row {
        margin: 10px 0;
        display: inline-block;
        width: 125px;
        height: 114px;
        margin: 5px 5px;
        border-radius: 7px;
        border: 1px solid #88888885;
        box-sizing: border-box;
    }

    .row-top {
        height: 22px;
        color: #ffffff;
        font-weight: 600;
        text-align: center;
        border-radius: 7px 7px 0 0;
    }

    .row-center {
        display: flex;
        padding: 0 5px;
    }

    .row-img {
        width: 58px;
        height: 68px;
        padding-top: 4px;
        padding-right: 4px;
    }

    .row-img-cry {
        width: 58px;
        height: 58px;
        margin-top: 7px;
    }

    .row-text {
        font-size: 12px;
        padding-top: 4px;
        height: 66px;
        overflow: hidden; //超出的文本隐藏
        // text-overflow: ellipsis; //溢出用省略号显示
        // white-space: nowrap; // 默认不换行；
        box-sizing: border-box;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        word-break: break-all;
    }

    .row-bottom {
        text-align: right;
        font-size: 12px;
        padding: 0 10px;
    }
</style>