<template>
    <div class="tansferRecord-list">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
        <el-dialog title="查看照片" width="900px" :visible.sync="dialogVisible" :close-on-click-modal="false">
            <div class="checkPicBox">
                <div class="photoTitle">移货前照片</div>
                <div class="checkPic transferBefore">
                    <img v-for="(item,index) in beforePicUrls" :key="index" :src="item">
                </div>
                <div class="photoTitle transferAfter">移货后照片</div>
                <div class="checkPic">
                    <img v-for="(item,index) in afterPicUrls" :key="index" :src="item">
                </div>
            </div>

        </el-dialog>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    search: [],
                    columns: [{
                            label: "移货前货柜MAC",
                            key: "beforeCounterMac",
                            width: 150    
                        },
                        {
                            label: "移货前货柜柜门编号",
                            key: "beforeDoorCode",
                            width: 160                        },
                        {
                            label: "移货后货柜MAC",
                            key: "afterCounterMac",
                            width: 150   
                        },
                        {
                            label: "移货后货柜柜门编号",
                            key: "afterDoorCode",
                            width: 160 
                        },
                        {
                            label: "商品品类",
                            key: "categoryName"
                        },
                        {
                            label: "商品名称",
                            key: "itemsName"
                        },
                        {
                            label: "商品售价",
                            key: "itemsPrice"
                        },
                        {
                            label: "货管员名称",
                            key: "counterUserName"
                        },
                        {
                            label: "货管员手机号",
                            key: "counterUserPhone"
                        },
                        {
                            label: "取货时间",
                            key: "takeTime"
                        },
                        {
                            label: "移货时间",
                            key: "stockTime"
                        },
                        // {
                        //     label: "移货照片",
                        //     key: "updatedTime",
                        //     type: "action-but",
                        //     align: "center",
                        //     opt: {
                        //         list: [{
                        //             label: "查看",
                        //             on(row) {
                        //                 _this.dialogVisible = true;
                        //                 _this.beforePicUrls = row.beforePicUrls;
                        //                 _this.afterPicUrls = row.afterPicUrls;
                                        
                        //             }
                        //         }]
                        //     }
                        // },
                        {
                            label: "移货状态",
                            key: "stateStr"
                        },
                    ]
                },
                dialogVisible: false,
                beforePicUrls: [],
                afterPicUrls: []
            };
        },
        created() {
            console.log("tansferRecord created!!");

        },
        activated() {
            console.log("tansferRecord activated!!");
            let containerType = this.$route.params.type;
        },
        mounted() {},
        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    counterId: this.$route.query.id
                };
                this.post("/mall-service/itemsTransferRecord/page/query", dto, {
                    isUseResponse: true
                }).then(res => {
                    res.data.data = res.data.list;
                    if(res.data.list && res.data.list.length >0) {
                        res.data.list.forEach(item => {
                            item.afterCounterMac = item.afterCounterMac ? item.afterCounterMac : '--';
                            item.afterDoorCode = item.afterDoorCode ? item.afterDoorCode : '--';
                            item.stateStr = ['取货成功','存货成功'][item.state - 1];
                            if(item.beforeRegion) {
                              item.beforeDoorCode = item.beforeRegion + item.beforeRegionNumber + '-' + item.beforeDoorCode;
                            }
                            if(item.afterRegion) {
                              item.afterDoorCode = item.afterRegion + item.afterRegionNumber + '-' + item.afterDoorCode;
                            }
                        });
                    }
                    opt.cb(res.data);
                });
            },
        }
    };
</script>
<style lang="scss" scoped>
    .tansferRecord-list {
        height: calc(100% - 143px);
    }

    .photoTitle {
        padding-bottom: 10px;
    }

    .checkPic img {
        width: 248px;
        height: 161px;
        padding-right: 10px;
    }

    .checkPicBox {
        margin: 0px 40px 40px 40px;
    }

    .transferBefore {
        padding-bottom: 30px;
    }
</style>