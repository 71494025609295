var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "containerDeatil-list" },
    [
      _c(
        "div",
        { staticClass: "title" },
        [_c("app-header", { attrs: { title: _vm.title, isShowBack: true } })],
        1
      ),
      _c(
        "ul",
        { staticClass: "tab-ul" },
        [
          _c(
            "li",
            {
              class: { ative: _vm.currentTab == "deviceDetails" },
              on: {
                click: function($event) {
                  _vm.toggleTab("deviceDetails")
                }
              }
            },
            [_vm._v("设备详情")]
          ),
          _vm.bindPlatformType == 1
            ? [
                _vm.isShowFreightTrackDetail
                  ? _c(
                      "li",
                      {
                        class: {
                          ative: _vm.currentTab == "freightTrackDetail"
                        },
                        on: {
                          click: function($event) {
                            _vm.toggleTab("freightTrackDetail")
                          }
                        }
                      },
                      [_vm._v("货道详情")]
                    )
                  : _vm._e(),
                !_vm.isRowCabinet && _vm.containerTypeVal != 2016
                  ? _c(
                      "li",
                      {
                        class: {
                          ative: _vm.currentTab == "replenishmentRecord"
                        },
                        on: {
                          click: function($event) {
                            _vm.toggleTab("replenishmentRecord")
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.replenishmentRecordTitle))]
                    )
                  : _vm._e(),
                _vm.isRowCabinet
                  ? _c(
                      "li",
                      {
                        class: { ative: _vm.currentTab == "rowCabinetRecord" },
                        on: {
                          click: function($event) {
                            _vm.toggleTab("rowCabinetRecord")
                          }
                        }
                      },
                      [_vm._v("排柜记录")]
                    )
                  : _vm._e(),
                !_vm.isRowCabinet
                  ? [
                      _vm.containerType == "L21BD" ||
                      _vm.containerType == "L21BX" ||
                      _vm.containerType == "L22BD" ||
                      _vm.containerType == "L22BX" ||
                      (_vm.containerType == "L25" &&
                        _vm.containerTypeVal !== 2001 &&
                        _vm.containerTypeVal !== 2006) ||
                      _vm.containerType == "L25AB"
                        ? _c(
                            "li",
                            {
                              class: {
                                ative: _vm.currentTab == "tansferRecord"
                              },
                              on: {
                                click: function($event) {
                                  _vm.toggleTab("tansferRecord")
                                }
                              }
                            },
                            [_vm._v("移货记录")]
                          )
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm.isStockRecord &&
                _vm.containerTypeVal !== 2001 &&
                _vm.containerTypeVal !== 2006
                  ? _c(
                      "li",
                      {
                        class: { ative: _vm.currentTab == "stockRecord" },
                        on: {
                          click: function($event) {
                            _vm.toggleTab("stockRecord")
                          }
                        }
                      },
                      [_vm._v("存货记录")]
                    )
                  : _vm._e(),
                _vm.containerTypeVal == 19
                  ? _c(
                      "li",
                      {
                        class: { ative: _vm.currentTab == "powerChangeRecord" },
                        on: {
                          click: function($event) {
                            _vm.toggleTab("powerChangeRecord")
                          }
                        }
                      },
                      [_vm._v("换电记录")]
                    )
                  : _vm._e(),
                _vm.isShowOpenDoorRecord
                  ? _c(
                      "li",
                      {
                        class: { ative: _vm.currentTab == "openDoorRecord" },
                        on: {
                          click: function($event) {
                            _vm.toggleTab("openDoorRecord")
                          }
                        }
                      },
                      [_vm._v("开柜门记录")]
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _c(
            "li",
            {
              class: { ative: _vm.currentTab == "upgradeRecord" },
              on: {
                click: function($event) {
                  _vm.toggleTab("upgradeRecord")
                }
              }
            },
            [_vm._v("升级记录")]
          )
        ],
        2
      ),
      _c("keep-alive", [_c(_vm.currentTab, { tag: "deviceDetails" })], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }