<template>
  <div class="orderRecordL21B-list">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
  </div>
</template>
<script>
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          search: [],
          columns: [{
              label: "订单编号",
              key: "id",
            },
            {
              label: "商品品类",
              key: "categoryName"
            },
            {
              label: "商品名称",
              key: "itemName"
            },
            {
              label: "数量",
              key: "buyCounts"
            },
            {
              label: "订单金额",
              key: "totalAmount"
            },
            {
              label: "支付方式",
              key: "payType"
            },
            {
              label: "会员姓名",
              key: "receiverName"
            },
            {
              label: "下单时间",
              key: "createdTime"
            },
            {
              label: "支付时间",
              key: "payTime"
            },
            {
              label: "支付状态",
              key: "stateStr"
            },
          ]
        },
      };
    },
    created() {
      console.log("orderRecordL21B created!!");

    },
    activated() {
      console.log("orderRecordL21B activated!!");
    },
    mounted() {},
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          counterId: this.$route.query.id
        };
        this.post("mall-service/orders/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          res.data.list.forEach(item => {
            item.stateStr = ["未支付", "支付中", "已支付", "支付失败", "订单取消", "支付超时"][item.state -
              1] //1新建未支付，2 支付中，3已支付(支付成功)，4支付失败  5 订单取消 6支付超时
            item.receiverName = item.receiverName ? item.receiverName : '--';
            if (item.orderItemsRespList) {
              item.categoryName = item.orderItemsRespList[0].categoryName;
              item.itemName = item.orderItemsRespList[0].itemName;
            }
            item.payTime = item.payTime ? item.payTime : "--";
            if (item.payMethod == 1) {
              item.payType = "微信支付";
            } 
            // else if (item.payMethod == 5) {
            //   item.payType = "微信支付(使用优惠券)";
            // } 
            else if (item.payMethod == 4) {
              item.payType = "会员余额支付";
            } 
            else if (item.payMethod == 6) {
              item.payType = "刷卡支付";
            } 
            // else if (item.payMethod == 7) {
            //   item.payType = "通联支付";
            // }
            
          });
          opt.cb(res.data);
        });
      },
    }
  };
</script>
<style lang="scss" scoped>
  .orderRecordL21B-list {
    height: calc(100% - 143px);
  }
</style>