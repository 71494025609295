var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c("div", [_vm._v("\n        " + _vm._s(_vm.storeName) + "\n    ")]),
    _c(
      "div",
      { staticClass: "main-title" },
      [
        _c("span", { staticClass: "main-address" }, [
          _vm._v(_vm._s(_vm.storeAddress))
        ]),
        _vm._l(_vm.freightTrackList, function(item, index) {
          return _c("span", { key: index, staticClass: "main-address" }, [
            _vm._v("货道类型" + _vm._s(item.channelType) + "："),
            _c("span", { staticClass: "highlightText" }, [
              _vm._v(_vm._s(item.count))
            ]),
            _vm._v("个")
          ])
        })
      ],
      2
    ),
    _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "main-content-left" }, [
        _c(
          "div",
          { staticClass: "productView" },
          [
            _c("div", { staticClass: "productView-tip" }, [
              _c("div", { staticClass: "tip-cont" }, [
                _c("div", { staticClass: "tip-img img1" }),
                _c("div", { staticClass: "tip-title" }, [
                  _vm._v("满货"),
                  _c("span", [_vm._v(_vm._s(_vm.full))])
                ])
              ]),
              _c("div", { staticClass: "tip-cont" }, [
                _c("div", { staticClass: "tip-img img2" }),
                _c("div", { staticClass: "tip-title" }, [
                  _vm._v("缺货"),
                  _c("span", [_vm._v(_vm._s(_vm.lack))])
                ])
              ]),
              _c("div", { staticClass: "tip-cont" }, [
                _c("div", { staticClass: "tip-img img3" }),
                _c("div", { staticClass: "tip-title" }, [
                  _vm._v("无货"),
                  _c("span", [_vm._v(_vm._s(_vm.miss))])
                ])
              ])
            ]),
            _vm._l(_vm.viewList, function(item, index) {
              return _c("div", { key: index, staticClass: "productView-row" }, [
                _c(
                  "div",
                  {
                    staticClass: "row-top",
                    class: {
                      img1: item.cargoState == 1,
                      img2: item.cargoState == 0,
                      img3: item.cargoState == 2
                    }
                  },
                  [_vm._v("\n                        " + _vm._s(item.code))]
                ),
                item.cargoState == 2
                  ? _c("div", { staticClass: "row-center" }, [_vm._m(0, true)])
                  : _c("div", { staticClass: "row-center" }, [
                      _c("img", {
                        staticClass: "row-img",
                        attrs: { src: item.itemPic }
                      }),
                      _c("div", { staticClass: "row-text" }, [
                        _vm._v(_vm._s(item.itemName))
                      ])
                    ]),
                _c("div", { staticClass: "row-bottom" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.cargoState != 2,
                          expression: "item.cargoState!=2"
                        }
                      ]
                    },
                    [_vm._v(_vm._s(item.itemPrice + " "))]
                  ),
                  _c("span", [_vm._v(_vm._s(item.cargoStateStr))])
                ])
              ])
            })
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "main-content-right" },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.tableData } },
            [
              _c("el-table-column", {
                attrs: { prop: "code", align: "center", label: "货道编号" }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "channelType",
                  align: "center",
                  label: "货道类型"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specification",
                  align: "center",
                  label: "货位长宽高"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "workableStr",
                  align: "center",
                  label: "货道状态"
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "itemNameStr",
                  align: "center",
                  label: "货道商品"
                }
              }),
              _c("el-table-column", {
                attrs: { prop: "itemPrice", align: "center", label: "商品价格" }
              }),
              _c("el-table-column", {
                attrs: { prop: "cargoSpace", align: "center", label: "货位" }
              }),
              _c("el-table-column", {
                attrs: { prop: "inventory", align: "center", label: "库存" }
              }),
              _c("el-table-column", {
                attrs: { prop: "freeSpace", align: "center", label: "空余货位" }
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: { width: "100%", height: "70px", "text-align": "center" }
      },
      [
        _c("img", {
          staticClass: "row-img-cry",
          attrs: { src: require("../../assets/ic_cry.png") }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }