<template>
  <div class="containerDeatil-list">
    <div class="title">
      <app-header :title="title" :isShowBack="true"></app-header>
    </div>
    <ul class="tab-ul">
      <li :class="{'ative':currentTab=='deviceDetails'}" @click="toggleTab('deviceDetails')">设备详情</li>
      <template v-if="bindPlatformType == 1">
        <li :class="{'ative':currentTab=='freightTrackDetail'}" @click="toggleTab('freightTrackDetail')" v-if="isShowFreightTrackDetail">货道详情</li>
        <li :class="{'ative':currentTab=='replenishmentRecord'}" @click="toggleTab('replenishmentRecord')" v-if="!isRowCabinet&&containerTypeVal!=2016">{{replenishmentRecordTitle}}</li>
        <li :class="{'ative':currentTab=='rowCabinetRecord'}" @click="toggleTab('rowCabinetRecord')" v-if="isRowCabinet">排柜记录</li>
        <template v-if="!isRowCabinet">
          <!-- L21BD L21BX L22BD L22BX的移货记录 -->
          <li :class="{'ative':currentTab=='tansferRecord'}"
            v-if="containerType=='L21BD'||containerType=='L21BX'||containerType=='L22BD'||containerType=='L22BX'||containerType=='L25'&& containerTypeVal !== 2001&& containerTypeVal !== 2006||containerType=='L25AB'"
            @click="toggleTab('tansferRecord')">移货记录</li>
          <!-- L2K L21 L22的订单记录 -->
          <!-- <li :class="{'ative':currentTab=='orderRecord'}"
            v-if="containerType=='L2K'||containerType=='L21'||containerType=='L22'" @click="toggleTab('orderRecord')">订单记录
          </li> -->
          <!-- L21B的订单记录 -->
          <!-- <li :class="{'ative':currentTab=='orderRecordL21B'}" v-if="containerType=='L21B'"
            @click="toggleTab('orderRecordL21B')">订单记录</li> -->
          <!-- L22B的订单记录 -->
          <!-- <li :class="{'ative':currentTab=='orderRecordL22B'}" v-if="containerType=='L22B'"
            @click="toggleTab('orderRecordL22B')">订单记录</li> -->

          <!-- L21BD的订单记录 -->
          <!-- <li :class="{'ative':currentTab=='orderRecordL21B'}" v-if="containerType=='L21BD'"
            @click="toggleTab('orderRecordL21B')">订单记录</li> -->
          <!-- L21BX的订单记录 -->
          <!-- <li :class="{'ative':currentTab=='orderRecordL21B'}" v-if="containerType=='L21BX'"
            @click="toggleTab('orderRecordL21B')">订单记录</li> -->
          <!-- L22BD的订单记录 -->
          <!-- <li :class="{'ative':currentTab=='orderRecordL22B'}" v-if="containerType=='L22BD'"
            @click="toggleTab('orderRecordL22B')">订单记录</li> -->
          <!-- L22BX的订单记录 -->
          <!-- <li :class="{'ative':currentTab=='orderRecordL22B'}" v-if="containerType=='L22BX'"
            @click="toggleTab('orderRecordL22B')">订单记录</li> -->
          <!-- L25的订单记录 -->
          <!-- <li :class="{'ative':currentTab=='orderRecordL22B'}" v-if="containerType=='L25'"
            @click="toggleTab('orderRecordL22B')">订单记录</li> -->
        </template>
        <li :class="{'ative':currentTab=='stockRecord'}" v-if="isStockRecord && containerTypeVal !== 2001 && containerTypeVal !== 2006" @click="toggleTab('stockRecord')">存货记录</li>
        <li :class="{'ative':currentTab=='powerChangeRecord'}" v-if="containerTypeVal == 19" @click="toggleTab('powerChangeRecord')">换电记录</li>
        <li :class="{'ative':currentTab=='openDoorRecord'}" v-if="isShowOpenDoorRecord" @click="toggleTab('openDoorRecord')">开柜门记录</li>
      </template>
      <li :class="{'ative':currentTab=='upgradeRecord'}" @click="toggleTab('upgradeRecord')">升级记录</li>
    </ul>
    <keep-alive>
      <deviceDetails :is="currentTab"></deviceDetails>
    </keep-alive>

  </div>
</template>
<script>
  import deviceDetails from './deviceDetails';
  import replenishmentRecord from './replenishmentRecord';
  import tansferRecord from './tansferRecord';
  import orderRecord from './orderRecord';
  import orderRecordL21B from './orderRecordL21B';
  import orderRecordL22B from './orderRecordL22B';
  import stockRecord from './stockRecord';
  import openDoorRecord from './openDoorRecord';
  import rowCabinetRecord from './rowCabinetRecord';
  import freightTrackDetail from './freightTrackDetail';
  import upgradeRecord from './upgradeRecord';
  import powerChangeRecord from './powerChangeRecord';
  export default {
    components: {
      deviceDetails,
      replenishmentRecord,
      tansferRecord,
      orderRecord,
      stockRecord,
      openDoorRecord,
      orderRecordL21B,
      orderRecordL22B,
      rowCabinetRecord,
      freightTrackDetail,
      upgradeRecord,
      powerChangeRecord,
    },
    data() {
      let _this = this;
      return {
        replenishmentRecordTitle: '补货记录',
        currentTab: 'deviceDetails',
        title: "货柜详情",
        isStockRecord: false,
        isRowCabinet: false,
        containerType: '', //货柜类型
        isShowOpenDoorRecord: true,
        isShowFreightTrackDetail: false,
        containerType: '', //货柜类型名称
        containerTypeVal: '',//货柜类型值
        bindPlatformType: 1,//绑定平台类型 1-云数商城 2-威富商城
      };
    },
    activated() {
      this.bindPlatformType = this.cache.get('bindPlatformTypeVal') || 1;
      this.currentTab = 'deviceDetails';
      this.containerType = this.$route.params.type;
      this.containerTypeVal = this.$route.query.type;
      if(this.bindPlatformType == 1) {
        if (this.$route.params.type == "L22" || this.$route.params.type == "L21B" || this.$route.params.type == "L22B" ||
          this.$route.params.type == "L21BD" || this.$route.params.type == "L21BX" || this.$route.params.type ==
          "L22BD" || this.$route.params.type == "L22BX" || this.$route.params.type == "L25") {
          this.isStockRecord = true;
        } else {
          this.isStockRecord = false;
        }
        if (['L25A'].indexOf(this.$route.params.type) != -1) {
          this.isRowCabinet = true;
        } else {
          this.isRowCabinet = false;
        }
        // 密码储物柜：不展示排柜记录，展示存货记录
        if(this.containerTypeVal == 2016) {
          this.isRowCabinet = false;
          this.isStockRecord = true;
        }
        if(['L21BD','L21BX','L22BD','L22BX','L25','L27','L27N','T160','T254'].indexOf(this.$route.params.type) != -1) {
          this.replenishmentRecordTitle = '补货/下架';
        }else {
          this.replenishmentRecordTitle = '补货记录';
        }
        if([20,21,2014].indexOf(Number(this.$route.query.type)) != -1) {
          // 弹簧机不展示开柜门记录
          this.isShowOpenDoorRecord = false;
          // 展示货道详情
          this.isShowFreightTrackDetail = true;
        }else {
          this.isShowOpenDoorRecord = true;
          this.isShowFreightTrackDetail = false;
        }
      }
    },
    mounted() {
      this.currentTab = 'deviceDetails';
    },
    deactivated() {
      let pathList = this.$route.path.split('/')
      if(pathList[2] != 'containerManagement') {
        this.cache.del('containerTypeVal');
        this.cache.del('bindPlatformTypeVal');
      }
    },
    methods: {
      toggleTab: function (tab) {
        this.currentTab = tab; // tab 为当前触发标签页的组件名
      },
    }
  };
</script>
<style lang="scss">
  .containerDeatil-list {
    .danger {
      color: #f56c6c;
    }

    .titleSpans {
      padding-bottom: 20px;
    }

    .titleSpan {
      color: #606266;
    }

    .info1 {
      padding: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      color: #606266;

      .txt {
        margin-right: 20px;
      }
    }

    .info {
      padding: 10px;
      margin-bottom: 20px;
      font-size: 14px;
      color: #606266;
      background-color: #e4e7ed;

      .txt {
        margin-right: 20px;
      }
    }

    // tab样式
    .tab-ul {
      list-style: none;
      padding-left: 0;
      margin-top: 0;
      height: 42px;
      box-sizing: border-box;
      margin-bottom: 40px;
      width: 100%;
      border-bottom: 2px solid #e4e7ed;

      li {
        cursor: pointer;
        padding-left: 18px;
        padding-right: 18px;
        height: 42px;
        line-height: 42px;
        float: left;
        text-align: center;
        margin-right: 13px;
        font-size: 15px;
        background-color: #ffffff;
        color: #303133;
        box-sizing: border-box;
        border-bottom: 2px solid #e4e7ed;

        &.ative {
          // color: #ffffff;
          // background: #5e74f1;
          color: #30BAC1;
          border-bottom: 2px solid #30BAC1;
        }
      }

      &::after {
        content: "";
        display: block;
        clear: both;
      }
    }

    .landlord-manage-box .el-tab-pane {
      height: 650px;
    }
  }
</style>