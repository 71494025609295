<template>
    <div class="powerChangeRecord-list">
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    search: [],
                    columns: [{
                            label: "换电编号",
                            key: "id",
                            align: "center",
                        },
                        {
                            label: "用户账号",
                            key: "memberName",
                            align: "center"
                        },
                        {
                            label: "手机号码",
                            key: "mobile",
                            align: "center"
                        },
                        {
                            label: "换电电池",
                            key: "itemName",
                            align: "center"
                        },
                        {
                            label: "换电时间",
                            key: "useTime",
                            align: "center"
                        },
                        {
                            label: "换电结果",
                            key: "stateStr",
                            align: "center"
                        },
                        {
                            label: "结果描述",
                            key: "resultStr",
                            align: "center"
                        },
                    ],
                },
            };
        },
        created() {},
        activated() {},

        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    counterId: this.$route.query.id,
                };
                this.post("mall-service/bc/change/battery/page", dto, {
                    isUseResponse: true
                }).then(res => {
                    res.data.data = res.data.list;
                    res.data.list.forEach(item => {
                        if(item.state == 0) {
                            item.stateStr = '成功'
                            item.resultStr = '开锁成功'
                        } else {
                            item.stateStr = '失败'
                        }
                        if(item.state == 1) {
                            item.resultStr = '柜门弹开失败'
                        } else if(item.state == 2){
                            item.resultStr = '连接从柜门失败'
                        } else if(item.state == 5){
                            item.resultStr = '柜门已经是打开状态'
                        } else if(item.state == 6){
                            item.resultStr = '蓝牙芯片通讯失败'
                        } else if(item.state == 30){
                            item.resultStr = '其他错误'
                        } else if(item.state == 100){
                            item.resultStr = '设备未响应开门结果'
                        } else if(item.state == 101){
                            item.resultStr = '并发场景未处理的开门请求'
                        } else if(item.state == 11){
                            item.resultStr = '秘钥校验失败'
                        }
                    });
                    opt.cb(res.data);
                });
            },
        }
    };
</script>
<style lang="scss" scoped>
    .powerChangeRecord-list {
        height: calc(100% - 143px);
    }
</style>