<template>
  <div class="replenishmentRecord-list">
    <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
    <div class="offDetail">
      <el-dialog title="下架详情" width="700px" :visible.sync="offDetailDialogVisible" :close-on-click-modal="false">
        <div class="offDetail-content-box">
          <div class="offDetail-content">
            <div class="offDetail-content-row">
              <div class="title">货柜信息</div>
              <div class="content">{{obj.groupName}}</div>
            </div>
            <div class="offDetail-content-row">
              <div class="title">下架商品名称</div>
              <div class="content">{{obj.itemsName}}</div>
            </div>
            <div class="offDetail-content-row">
              <div class="title">下架时间</div>
              <div class="content">{{obj.shelvesTime}}</div>
            </div>
            <div class="offDetail-content-row">
              <div class="title">下架人手机号</div>
              <div class="content">{{obj.shelvesPhone}}</div>
            </div>
            <div class="offDetail-content-row">
              <div class="title">下架人姓名</div>
              <div class="content">{{obj.shelvesUserName}}</div>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <el-button @click="offDetailDialogVisible = false">关闭</el-button>
        </div>
      </el-dialog>

    </div>
  </div>
</template>
<script>
  let stateStr = "";
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          search: [],
          columns: [{
              label: "补货人",
              key: "realName",
            },
            {
              label: "补货人手机号",
              key: "phone"
            },
            {
              label: "商品品类",
              key: "categoryName"
            },
            {
              label: "商品名称",
              key: "itemsName"
            },
            {
              label: "商品售价",
              key: "sellPrice"
            },
            {
              label: "补货时间",
              key: "updatedTime"
            },
            {
              label: "补货状态",
              key: "action",
              type: "action-back",
              opt: {
                list: [{
                  label: '补货成功',
                  color: true,
                }, {
                  label: '补货失败',
                  color: true,
                }, {
                  label: '已下架',
                  on(row) {
                    _this.offDetailDialogVisible = true;
                    _this.obj.doorName = row.doorName;
                    _this.obj.shelvesPhone = row.shelvesPhone ? row.shelvesPhone : '--';
                    _this.obj.shelvesTime = row.shelvesTime ? row.shelvesTime : '--';
                    _this.obj.shelvesUserName = row.shelvesUserName ? row.shelvesUserName : '--';
                    _this.picUrls = row.picUrls;
                    _this.obj.groupName = row.groupName ? row.groupName + ' ' + row.doorName : '--';
                    _this.obj.region = row.region;
                    _this.obj.regionNumber = row.regionNumber;
                    _this.obj.itemsName = row.itemsName;
                  }
                }]
              }
            },
          ],
        },
        offDetailDialogVisible: false,
        obj: {
          shelvesPhone: '',
          shelvesTime: '',
          shelvesUserName: '',
          groupName: '',
          region: '',
          regionNumber: '',
          doorName: '',
          itemsName: '',
        },
        picUrls: [],
      };
    },
    created() {
      console.log("replenishmentRecord created!!");

    },
    activated() {
      console.log("replenishmentRecord activated!!");
      if (['T160','T254'].indexOf(this.$route.params.type) != -1) {// 弹簧机
        // 删除柜门编号
        this.opt.columns.forEach((item, index) => {
          if (item.key == "doorName") {
            this.opt.columns.splice(index, 1);
          }
        })

        if (this.opt.columns[0].key != "doorCode") {
          this.opt.columns.unshift({
            label: "货道编号",
            key: "doorCode"
          })
        }
        this.opt.columns.forEach((item, index) => {
          if (item.key == 'sellPrice' && this.opt.columns[index + 1].key != 'amount') {
            this.opt.columns.splice(index + 1,0,{
              label: "补货/下架",
              key: "amount",
            })
          }
        })
      } else {
        // 删除货道编号和补货数量
        this.opt.columns.forEach((item, index) => {
          if (item.key == "doorCode") {
            this.opt.columns.splice(index, 1);
          }
          if (item.key == "amount") {
            this.opt.columns.splice(index, 1);
          }
        })
        
        if (['L22', 'L22B', 'L22BX', 'L22BD', 'L25','L25AB'].indexOf(this.$route.params.type) != -1) {
          // L系列的多柜门
          if (this.opt.columns[0].key != "doorName") {
            this.opt.columns.unshift({
              label: "柜门编号",
              key: "doorName"
            })
          }
        } else {
          // L系列的单柜门 
          this.opt.columns.forEach((item, index) => {
            if (item.key == "doorName") {
              this.opt.columns.splice(index, 1);
            }
          })
        }
      }
    },
    mounted() {},
    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          counterId: this.$route.query.id
        };
        this.post("mall-service/itemsRecord/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          for (let i = 0; i < res.data.data.length; i++) {
            let item = res.data.data[i];
            item.action = [false, false, false];
            if (item.state == 1) {
              item.action = [true, false, false];
            } else if (item.state == 2) {
              item.action = [false, true, false];
            } else if (item.state == 3) {
              item.action = [false, false, true];
            }
            if (item.region) {
              item.doorName = item.region + item.regionNumber + '-' + item.doorName;
            }
            if(item.doorCode < 10) {
              item.doorCode = '0' + item.doorCode;
            }
          }
          opt.cb(res.data);
        });
      },
    }
  };
</script>
<style lang="scss" scoped>
  .replenishmentRecord-list {
    height: calc(100% - 143px);
  }

  .dialog-footer {
    text-align: center;
    margin-top: 36px;
  }

  .offDetail-content-box {
    margin: 0 auto;
    min-width: 320px;
    text-align: center;
  }

  .offDetail-content {
    // width: 626px;
    // margin: 0 auto
    display: inline-block;
  }

  .offDetail-content-row {
    margin-bottom: 8px;
    text-align: left;

    .title {
      float: left;
      text-align: right;
      width: 120px;
      display: inline-block;
      color: #aaaaaa;
    }

    .content {
      padding-left: 20px;
      display: inline-block;
      max-width: 478px;
      min-width: 136px;
      text-align: left;
    }

  }

  .picBox {
    margin-left: 8px;
    display: inline-block;

    img {
      width: 154px;
      height: 126px;
      padding-left: 10px;
      margin-top: 6px;
    }
  }
</style>